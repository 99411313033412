.Dropdown-root {
    position: relative;
  }
  
  .Dropdown-control {
    position: relative;
    overflow: hidden;
    width: 250px;
    background-color:  rgba(220, 220, 220, 0.5);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    box-sizing: border-box;
    color: #fff;
    font-weight: bold;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease;
  }
  
  .Dropdown-arrow {
    border-color: #fff transparent transparent;
    border-style: solid;
    border-width: 8px 8px 0;
    content: ' ';
    display: block;
    height: 0;
    margin-top: -ceil(2.5);
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0
  }
  
  .is-open .Dropdown-arrow {
    border-color: transparent transparent #fff;
    border-width: 0 8px 8px;
  }
  
  .Dropdown-menu {
    background-color:  rgba(220, 220, 220, 0.5);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
  }
  
  .Dropdown-option {
    box-sizing: border-box;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    display: block;
    padding: 8px 10px;
  }
  
  .Dropdown-option:last-child {
    border-bottom-right-radius: 2px;
     border-bottom-left-radius: 2px;
  }
  
  .Dropdown-option:hover,   .Dropdown-option.is-selected {
    background-color: rgba(255, 255, 255, 0.3);
    color: #fff;
  }
  
  .Dropdown-noresults {
    box-sizing: border-box;
    color: #fff;
    cursor: default;
    display: block;
    padding: 8px 10px;
  }

  .Dropdown-menu::-webkit-scrollbar {
    width: 10px;
  }
  
  .Dropdown-menu::-webkit-scrollbar-track {
    background-color: rgba(220, 220, 220, 0.5); 
  }
  
  .Dropdown-menu::-webkit-scrollbar-thumb {
    background-color: #fff; 
    border-radius: 5px; 
  }