/* setup margins and paddings */

body {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

.padding-top10{
    padding-top: 10%;
  }
  
.padding-top{
    padding-top: 1rem;
}

/* multiple page css */

.background {
    background-image: linear-gradient(#66A6FF, #89F7FE); 
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}

.title {
  font-size: 4rem;
  line-height: 4rem;
}

.description {
  font-size: 1.3rem;
}

.subtitle {
  font-size: 2.5rem;
}

.bold {
  font-weight: bold;
}

.button {
  background-color: rgba(220, 220, 220, 0.5);
  color: white; 
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  width: 10rem;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}

.score {
font-size: 8rem;
line-height: 8rem;
}

.logo {
height: 45px;
}

/* home page css */

.homepage {
    width: 100%;
    min-height: 200vh;
    color: #fff;
}

.content-homepage {
    min-height: 100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.team-info {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    padding: 1rem 1rem 0 0;
}
  
.full-logo {
    width: 50%;
    align-self: center;
}

.scroll-to-info {
    text-align: center;
    padding: 1rem;
}

.doubleArrow {
    width: 30px;
}

.infoIcon {
    width: 50px;
} 

.content-info {
    min-height: 100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.text-box {
    background-color: rgba(255, 255, 255, 0.2);
    width: 70%;
    padding: 2rem;
    border: none;
    border-radius: 20px;
}

/* team page css */

.teampage {
    width: 100%;
    min-height: 100vh;
    color: #fff;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.portret {
    border: 1rem solid #fff;
    border-radius: 5rem;
    object-fit: cover;
    
}

/* choice page css */

.choice {
    min-height: 100vh;
    display:flex;
    justify-content: center;
    color: white;
}
.content-choice {
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.icon-choice {
    width: 4rem;
}

.colomn-box {
    display: flex;
    flex-direction: row;
    gap: 10%;
}

.column {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: start;
    gap: 1rem;
}

/* score page css */
.bg-75-100{
  background-image: linear-gradient(#39F3BB, #90F9C4);
}

.bg-50-74{
    background-image: linear-gradient(#FAC43B, #F9E833);
  }

.bg-25-49{
  background-image: linear-gradient(#FF5858, #f09819);
}

.bg-1-24{
  background-image: linear-gradient(#CB2D3E, #E96B61);
}

.bg-0{
  background-image: linear-gradient(#232526,#3D2929, #EF473A);
}

.scorepage {
  width: 100%;
  height: 100%;
}

.content-scorepage {
  width: 50vw;
  min-height: 100vh;
  margin: auto;
  color: white;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.row1 {
  flex: 0.75;
}

.row2{
  flex: 0.25;
}

/* location page css */
.locationpage {
    min-height: 100vh;
    display:flex;
    justify-content: center;
    color: white;
}

.content-locationpage {
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.dropdown{
    flex: 0.6;
}
